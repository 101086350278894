export const NAV_LIST = [
  {
    name: 'Home',
    hash: 'home'
  },
  {
    name: 'BTCST',
    hash: 'btcst'
  },
  {
    name: '&tau;',
    hash: 'tau'
  },
  {
    name: 'Stats',
    hash: 'stats',
  },
  {
    name: 'Partners',
    hash: 'partners'
  }
];
